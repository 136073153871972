import { IObservableValue, observable } from "mobx";
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { Dimensions, ScaledSize } from "react-native";
import { withStatus } from "./helpers/withStatus"
import { LoadingInfoModel } from "./helpers/typesModels";
import { LOADINGINFO_TYPE } from "../voolks/types";

/**
 * Model description here for TypeScript hints.
 */

const flashmessage: IObservableValue<string> = observable.box("");
const alertmessage: IObservableValue<Object> = observable.box({title:"",body:""});
const windowDimensions: IObservableValue<ScaledSize> = observable.box({ scale: 1, fontScale: 1, width: 200, height: 200 })
const isLargeScreen: IObservableValue<boolean> = observable.box(false);
windowDimensions.set(Dimensions.get("window"));
isLargeScreen.set(windowDimensions.get().width >= 1600)
Dimensions.addEventListener('change', ({ window }) => {
  windowDimensions.set(window);
  isLargeScreen.set(windowDimensions.get().width >= 1600);
  console.log("WINDOW CHANGE")
  //isLargeScreen.set(window.width >= 1600)
})



export const StatusStoreModel = types
  .model("StatusStore")
  .props({
    legend: types.array(types.string),
    loadingInfoLst: types.array(LoadingInfoModel)
  })
  .extend(withStatus)
  .views((self) => ({
    get flashmessage() {
      return flashmessage.get() as string;
    },
    set flashmessage(value: string) {
      flashmessage.set(value);
    },
    get windowDimensions() {
      return windowDimensions.get();
    },
    get isLargeScreen() {
      return isLargeScreen.get();
    },
    get alertMessage() {
      return alertmessage.get();
    }
  }))
  .actions((store) => ({
    clearLoadingInfo: () => {      
      store.loadingInfoLst.splice(0,store.loadingInfoLst.length);
    },
    addLoadingInfo: (info: LOADINGINFO_TYPE) => {
      info.id = info.id ?? new Date().getMilliseconds();
      store.loadingInfoLst.push({ ...info });
      return info.id;
    },
    updLoadingInfo: (info: LOADINGINFO_TYPE) => {
      const _info = store.loadingInfoLst.find(item => item.id == info.id)
      if (_info != undefined) {
        _info.spinner = info.spinner ?? _info.spinner;
        _info.title = info.title ?? _info.title;
      }
    },
    rmLoadingInfo: (info: LOADINGINFO_TYPE) => {
      store.loadingInfoLst.splice(0, 1);
    }
  }))
  .actions((store) => ({
    clearFlashMessage: () => {
      flashmessage.set("");
    }
  }))
  .actions((store) => ({
    setAlertMessage: (title:string,body:string) =>{
      console.log("SetAlertMessage",title)
      alertmessage.set({title,body});
    },
    setFlashMessage: (message: string, timeoutMS: number = 5000) => {      
      flashmessage.set(message);
      setTimeout(() => { store.clearFlashMessage(); }, timeoutMS)
    },
    reloadPayloadElements: (fieldsId: string[]) => {
      console.log("reloadPayloadElements - BroadCast")
    },
    reloadPayloadElementFromPopulate: (data: any[], populateValues: any, parentId: string | undefined) => {
      console.log("reloadPayloadElementFromPopulate - BroadCast")
    },
    reloadPayloadElement: (data: any, parentId: string | undefined) => {
      console.log("reloadPayloadElement - BroadCast")
    },
    configKBToolbar: ({ type: string, fn: Function }) => {
      console.log("configKBToolbar - BroadCast")
    }
  }))
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface Status extends Instance<typeof StatusStoreModel> { }
export interface StatusSnapshotOut extends SnapshotOut<typeof StatusStoreModel> { }
export interface StatusSnapshotIn extends SnapshotIn<typeof StatusStoreModel> { }
export const createStatusDefaultModel = () => types.optional(StatusStoreModel, {})
